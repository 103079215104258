const baseEvent = {
  event: 'loadEventData',
  eventCategory: 'STL',
  nonInteractive: false,
  customMetrics: {},
  _clear: true
}

export default {
  onClickOpenModal: (eventLabel: string, customVariables: Record<string, string> = {}) => ({
    ...baseEvent,
    eventAction: 'Open Modal Click',
    eventLabel,
    customVariables
  }),
  onSelectSize: (eventLabel: string, customVariables: Record<string, string> = {}) => ({
    ...baseEvent,
    eventAction: 'Select Size',
    eventLabel,
    customVariables
  }),
  onClickCloseModal: (eventLabel: string, customVariables: Record<string, string> = {}) => ({
    ...baseEvent,
    eventAction: 'Close Modal Click',
    eventLabel,
    customVariables
  }),
  onClickShowFullOutfit: (eventLabel: string, customVariables: Record<string, string> = {}) => ({
    ...baseEvent,
    eventAction: 'Show Full Outfit Click',
    eventLabel,
    customVariables
  })
}
