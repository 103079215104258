<template>
  <div>
    <nuxt-layout>
      <nuxt-page :keepalive="{ max: 5, include: 'ProductCategoryPage,ProductSearchPage' }" />
    </nuxt-layout>
  </div>

  <client-only>
    <screen-toast />
    <screen-to-top v-if="$feature.enableBackToTopButton" />
  </client-only>

  <app-dialogs />
  <app-dynamic-dialogs />
  <!-- This component allows to insert app-level content in each domain -->
  <app-content />
</template>

<script lang="ts" setup>
const { api, brandClasses = '', storeIds = {} } = useAppConfig()
const { $gtm, $locale, $t, $os } = useNuxtApp()
const countryCode = useCountryCode().toLocaleLowerCase()
const { gitHash, pwa: { manifests } } = useRuntimeConfig().public
const route = useRoute()
const router = useRouter()
const checkoutStore = useCheckoutStore()
const cart = useCartStore()
const { tempTestDataExportExperiment, tempTestFeatureChangeExperiment } = useFeatureFlags()

const googleSiteVerification = api.googleSiteVerification?.[$locale]
const manifest = replacePlaceholders(manifests[useLocale()], { origin: useOrigin() })
const storeId = storeIds?.storeId?.[countryCode]
const appleTouchIcons = manifest.icons?.filter(({ src }) => src.includes('touch-icon'))
  .map(({ src: href, sizes }) => ({
    href,
    ...(sizes && { sizes }),
    rel: 'apple-touch-icon'
  })) || []

useHead({
  htmlAttrs: {
    'lang': $locale,
    'data-os': $os
  },
  titleTemplate: (pageTitle) => titleTemplate($t, pageTitle),
  bodyAttrs: {
    class: `brand-base ${brandClasses}`
  },
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { name: 'format-detection', content: 'telephone=no' },
    { name: 'git-commit', content: gitHash },
    { name: 'theme-color', content: manifest.theme_color },
    { name: 'locale', content: $locale },
    storeId && { name: 'storeId', content: storeId },
    { 'http-equiv': 'accept-ch', 'content': 'width' },
    googleSiteVerification && { name: 'google-site-verification', content: googleSiteVerification }
  ].filter(Boolean),
  link: [
    ...appleTouchIcons,
    { rel: 'icon', href: '/favicon.ico', sizes: '48x48' },
    { rel: 'icon', href: '/favicon.svg', sizes: 'any', type: 'images/svg+xml' },
    {
      rel: 'manifest',
      href: `data:application/manifest+json;charset=utf-8,${encodeURIComponent(JSON.stringify(manifest))}`
    }
  ]
})

const pushPageEvents = async (route) => {
  if (!$gtm.disableAppEvents?.includes(route.name)) {
    $gtm?.push('page.onLoadPageData', route, { pageTitle: await getPageTitle() })
    $gtm.push('user.onLoadUserData', await getUserEventData())
    $gtm?.push('page.onRouterChange', route)
    $gtm.push('cart.onCartLoad', cart)
  }
}

onMounted(() => {
  pushPageEvents(router.currentRoute.value)

  console.info('UI version:', {
    commit: gitHash
  })

  console.info('tempTestDataExportExperiment', tempTestDataExportExperiment)
  console.info('tempTestFeatureChangeExperiment', tempTestFeatureChangeExperiment)

  // A solution until https://github.com/nuxt/nuxt/issues/9483 is solved
  window.history.scrollRestoration = 'auto'

  if (checkoutStore.order.orderNumber && !['order-confirmation', 'checkout'].some((it) => route.fullPath.includes(it)))
  // Sometimes the user can go from order-confirmation page to closing the page, in that case the pinia store doesn't get cleared
  // So, if the current route, when mounted, it's not order confirmation or checkout, we have to clear the local storage of the order
    checkoutStore.resetOrder()
})

const destroyAfterFn = router.afterEach((newRoute) => {
  useNuxtApp().hooks.hookOnce('page:finish', () => {
    pushPageEvents(newRoute)
  })
})

onBeforeUnmount(() => {
  destroyAfterFn()
})
</script>
