<template>
  <transition appear :duration="250" name="nested">
    <component
      :is="dialog.type === 'panel' ? VfPanel : VfModal"
      v-if="dialog"
      v-slot="attrs"
      :data-test-id="`${dialog.type}-${dialog.name}`"
      :size="dialog.size"
      :type="dialog.type"
      @resolve="closeDialog"
    >
      <suspense>
        <component
          :is="`dialog-${dialog.name}`"
          v-bind="{ ...attrs, ...dialog.props }"
          @resolve="closeDialog"
        />
        <template #fallback>
          <div style="min-height: 13.5rem">
            <vf-page-loader />
          </div>
        </template>
      </suspense>
    </component>
  </transition>
  <vf-page-loader v-if="isLoading" />
</template>

<script lang="ts" setup>
import { VfModal, VfPanel } from '#components'
import { InjectKeyDialog } from '#core/utils/createDialog'
import type { DialogContent } from '#types/components/cms/dialog'
import type { DialogName, DialogSize, DialogType } from '#types/composables/useDialog'
import type { DialogGuardAction, DialogGuardRule, DialogGuards } from '#types/config/components/dialog'

const router = useRouter()
const route = useRoute()
const { getModal, getSections } = useCms()
const { loggedIn } = storeToRefs(useAuthStore())

const isLoading = ref(false)

const dialog = ref<{
  type?: 'panel' | 'modal'
  name?: string
  props?: Record<string, any>
  size?: DialogSize
} | null>(null)
const typeRef = ref<'modal' | 'panel'>()

provide(InjectKeyDialog, { type: typeRef as any })

const initialData = {
  'sign-up': async () => {
    const eSpots = (await getSections.$signUpModal())?.content.sectionsMap

    return {
      props: { eSpot: eSpots?.['below-sign-up'] },
      size: eSpots?.['below-sign-up'] ? 'md' : 'xs'
    }
  }
}

const dialogGuardRules: Array<{ name: DialogGuardRule, condition: () => boolean }> = [
  { name: 'guest', condition: () => !loggedIn.value },
  { name: 'registered', condition: () => !!loggedIn.value }
]

const dialogGuardActions: Record<DialogGuardAction, Function> = {
  block: () => router.replace({ hash: undefined, query: route.query }),
  redirect: (target: string) => router.push({ hash: undefined, query: route.query, path: target })
}

const handleDialogGuardActions = (guards: NonNullable<DialogGuards>): boolean => {
  const guardActions = dialogGuardRules.filter(
    (rule) => guards[rule.name] && rule.condition()
  ).map(({ name }) => guards[name])
  if (guardActions.length) {
    guardActions.forEach(({ action, target }) => dialogGuardActions[action](target))
    return true
  }
  return false
}

async function getDialog(hash: string) {
  const startingIndex = hash.search(/#modal=|#panel=/)

  // return early if hash does not include #modal= or #panel=
  if (startingIndex === -1) return

  const [type, nameWithPossibleQueryString = ''] = hash.substring(startingIndex + 1).split('=') as [DialogType, string]
  const [nameWithoutQueryString] = nameWithPossibleQueryString.split('?')
  const [name, param] = nameWithoutQueryString.split('_') as [DialogName, string | undefined]
  const { guards } = useAppConfig().components.dialog[capitalize(name, { split: '-', join: '', isCamel: true })] || {}
  if (guards && handleDialogGuardActions(guards)) return // no need to further process and trigger dialog

  typeRef.value = type

  if (name === 'content' && param) {
    isLoading.value = true
    const content = await getModal<DialogContent>(param)
    isLoading.value = false

    return {
      type,
      name,
      props: { content },
      size: content.modalSize
    }
  }

  /* Quick Shop - pass a product ID as the param to trigger the interface
   * Example: #modal=quickshop_TB0A161G231
   */
  if (name === 'quickshop' && param) {
    if (!PRODUCT_ID_REGEX.test(param)) throw new Error(`Invalid Product ID: ${param}`)
    return {
      type,
      name,
      props: { productId: param },
      size: { sm: 'full', lg: 'lg' }
    }
  }
  const initial = await initialData[name]?.()

  return { type, name, ...initial }
}

watchImmediate(() => route.hash, async (hash) => {
  try {
    if (!hash) return

    const data = await getDialog(hash)

    if (!data) return

    await closeDialogs()

    if (dialog.value) {
      dialog.value = null
      await nextTick()
    }

    dialog.value = data
  }
  catch (e) {
    log.error(e)
  }
})

const closeDialog = () => {
  router.replace({ hash: undefined, query: route.query })
  dialog.value = null
}
</script>
