import type { HookResult } from '@nuxt/schema'

interface Header {
  transparent?: boolean
  expanded?: boolean
}

declare module 'vue-router' {
  interface RouteMeta {
    header?: Header
  }
}

declare module '#app' {
  interface RouteMeta {
    header?: Header
  }

  interface RuntimeNuxtHooks {
    'header:update': () => HookResult
  }
}

export default defineNuxtRouteMiddleware(({ meta }) => {
  const config = useAppConfig().components.vf.header
  const app = useNuxtApp()
  const header = useHeaderStore()

  const updateHeader = () => {
    header.transparent = meta.header?.transparent ?? config.transparent ?? false
  }

  app.hooks.hookOnce(import.meta.client ? 'page:finish' : 'vue:setup', updateHeader)
  app.hooks.hookOnce('header:update', updateHeader)
})
