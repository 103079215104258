// https://digital.vfc.com/wiki/display/ECOM15/Topnav+and+Footer

const baseEvent = {
  event: 'loadEventData',
  eventCategory: 'Navigation',
  nonInteractive: false,
  customMetrics: {},
  customVariables: {},
  _clear: true
}

export default {
  onClickUtilityLink: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Global Utility Nav',
    eventLabel
  }),
  onClickTopNavLink: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Global Header Nav',
    eventLabel
  }),
  onClickMobileMenuSection: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Mobile Mega Menu',
    eventLabel: `Expand: ${eventLabel}`
  }),
  onClickHamburgerMenu: (expand: boolean) => ({
    ...baseEvent,
    eventAction: 'Mobile Hamburger Menu',
    eventLabel: expand ? 'Expand' : 'Close'
  }),
  onClickCountrySelector: () => ({
    ...baseEvent,
    eventCategory: 'Country Selector',
    eventAction: 'Change Country Click',
    eventLabel: ''
  }),
  onClickSearchContent: (eventLabel?: string) => ({
    ...baseEvent,
    eventAction: 'Search Modal Content',
    eventLabel
  }),
  onClickMiniCartModalCTA: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Minicart',
    eventLabel
  })
}
