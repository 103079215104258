const consentGroups = {
  required: 'C0001',
  performanceAndAnalytics: 'C0002',
  functional: 'C0003',
  targeting: 'C0004',
}

export type ConsentStatus = Record<keyof typeof consentGroups, boolean>

const getCookie = (cName) => {
  const name = `${cName}=`
  const cArr = decodeURIComponent(document.cookie).split('; ')
  const cookie = cArr.find((val) => val.indexOf(name) === 0)
  return cookie?.substring(name.length) || undefined
}

export const getCookieConsent = (region?: string): ConsentStatus => {
  const url = new URL(location.href)
  const isEmea = region === 'EMEA'
  const isPreview = url.searchParams.get('preview') === 'true'
  const cookie = decodeURIComponent(getCookie('OptanonConsent') || '')

  return Object.keys(consentGroups).reduce((acc, key) => ({
    ...acc,
    [key]: !isEmea || isPreview || cookie.includes(`${consentGroups[key]}:1`)
  }), {} as ConsentStatus)
}

/**
 * Determines whether targeting is accepted based on the provided cookie and environment flags.
 * @param groups List of the cookie consent groups to take into account
 * @returns A boolean indicating whether targeting for all passed groups is accepted.
 * @category Utils
 * @remarks Read more about oneTrust and targeting here https://digital.vfc.com/wiki/display/AN/OneTrust
 */
export const hasCookieConsent = (groups: (keyof typeof consentGroups)[]) => {
  const consent = getCookieConsent(useRegion())

  return groups.every((key) => consent[key])
}

/**
 * Determines whether targeting is accepted based on the provided cookie and environment flags for prefetch script.
 * @param groups List of the cookie consent groups to take into account
 * @returns A boolean indicating whether targeting for all passed groups is accepted.
 * @category Utils
 * @remarks Read more about oneTrust and targeting here https://digital.vfc.com/wiki/display/AN/OneTrust
 */
export const hasCookieConsentPrefetched = (groups: (keyof typeof consentGroups)[]) => {
  const consent = getCookieConsent(window.vfaConfig.region)

  return groups.every((key) => consent[key])
}

/**
 * Determines whether the user has interacted with the cookie consent banner.
 * Regardless if the user has accepted or rejected the cookies.
 */
export const hasCookieConsentInteraction = () => !!getCookie('OptanonAlertBoxClosed')
