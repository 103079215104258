<template>
  <error-403 />
  <cms-section
    v-if="$feature.enable404Espots && eSpots?.['lazy-main']"
    class="mb-22 mt-4 md:mb-20"
    :section="eSpots?.['lazy-main']"
  />
</template>

<script lang="ts" setup>
const { $feature } = useNuxtApp()
const eSpots = (await useCms().getSections.error()).data.value?.content.sectionsMap
</script>
