type EmployeeApiCookie = {
  employeeToken: string
  employeeExpiryTime: string
}

export default defineNuxtPlugin(() => {
  const { clearSession } = useBuyInStoreStore()
  const employeeApiCookie = useApiCookie<EmployeeApiCookie>(cookiesPostfix.Employee)
  let employeeSessionTimeout

  const employeeHasToken = computed(() =>
    !!(employeeApiCookie.value?.employeeToken && employeeApiCookie.value?.employeeExpiryTime))

  whenever(() => employeeHasToken.value, () => {
    const expiryDate = new Date(employeeApiCookie.value.employeeExpiryTime)
    employeeSessionTimeout = setTimeout(clearSession, expiryDate.getTime() - new Date().getTime())
  }, { immediate: true })

  whenever(() => !employeeHasToken.value, () => clearTimeout(employeeSessionTimeout))
})
