import type { ProductUpsell } from '#types/product'
import type { ProductContext } from '#types/gtm'

const baseEvent = {
  event: 'loadEventData',
  eventCategory: 'PDP',
  eventLabel: undefined,
  nonInteractive: false,
  customMetrics: {},
  customVariables: {},
  _clear: true
}

export default {
  onUpsellImpression: (
    upsells: ProductUpsell[]
  ) => ({
    ...baseEvent,
    event: 'productImpressions',
    eventAction: 'Product Impressions',
    eventCategory: 'Enhanced Ecommerce',
    nonInteractive: true,
    ecommerce: {
      currencyCode: upsells[0]?.price.currency,
      impressions: getProductUpsellItemObjectList(upsells)
    },
    customVariables: {
      list_type: 'component',
      productLayout: '1 row',
      sortOption: 'no',
      filters: []
    }
  }),
  onUpsellClick: (upsell: ProductUpsell, { action }: Pick<ProductContext, 'action'>) => ({
    ...baseEvent,
    event: 'productClick',
    eventAction: 'Product Clicks',
    eventCategory: 'Enhanced Ecommerce',
    eventLabel: upsell.id,
    ecommerce: {
      currencyCode: upsell.price.currency,
      click: {
        actionField: {
          action,
          affiliation: `${useAppConfig().api.brandPrefix}-${useCountryCode()}`,
          list: 'UpSellCompleteTheLook'
        },
        products: getProductUpsellItemObjectList([upsell])
      }
    }
  }),
  onCrossSellImpression: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Cross-sell_Impressions',
    eventLabel
  }),
  onCrossSellSelect: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Cross-sell_Add Click',
    eventLabel
  }),
  onCrossSellDeselect: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Cross-sell_Remove Click',
    eventLabel
  }),
  onCrossSellDetails: (eventLabel: string) => ({
    ...baseEvent,
    eventAction: 'Cross-sell_Details Click',
    eventLabel
  })
}
