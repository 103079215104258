import type { LDMultiKindContext } from 'launchdarkly-node-server-sdk'
import { getLocaleFromDomain } from '#brands/utils/i18n'

/**
 * Retrieves LaunchDarkly context based on headers and default locale.
 * @param headers HTTP headers containing context information.
 * @returns LaunchDarkly context object.
 * @category Utils
 */
export const getLDContext = (headers?, userId?, auth?, user?) => {
  const {
    brand,
    isNetlify,
    targetEnv,
    disableProxyForNetlify,
    i18n: {
      defaultLocale
    }
  } = useRuntimeConfig().public

  const { locale: localeFromHeaders, 'x-ld-passthrough': ldPassthroughHeader = '', host } = headers || {}
  const locale = localeFromHeaders || getLocaleFromDomain(host) || defaultLocale
  const defaultContextKey = `${brand}_${locale}`

  const brandContext = {
    brand: {
      kind: 'brand',
      key: defaultContextKey,
      name: brand,
      locale,
    }
  }

  const envContext = {
    env: {
      kind: 'env',
      key: targetEnv,
    }
  }

  // since targeting and segmentation rules in LD are defined using the "user" context kind,
  // we need to keep the below props on the user until the multi-context is fully rolled out
  // we've re-assigned the rules using other contexts
  const userContext = {
    user: {
      kind: 'user',
      key: userId || defaultContextKey,
      brand,
      disableProxyForNetlify,
      ldPassthroughHeader,
      locale,
      targetEnv,
      isNetlify
    }
  }

  // Use the email address as the consumer key to centralize
  // context instances across brands
  const consumerContext = {
    consumer: {
      kind: 'consumer',
      key: btoa(user?.details?.email), // base64 encode user's email for privacy
      consumerType: auth?.consumerType,
      isLoyaltyMember: !!user?.isLoyaltyMember
    }
  }

  return {
    kind: 'multi',
    ...brandContext,
    ...envContext,
    ...userContext,
    ...(user ? consumerContext : {}),
  } as LDMultiKindContext
}
