// https://digital.vfc.com/wiki/display/ECOM15/Forms%3A+Login
// https://digital.vfc.com/wiki/display/ECOM15/Forms%3A+Registration
import type { FlowTypes, FormLocation, FormMethods } from '#types/gtm'

type Interaction = 'Impression' | 'Email' | 'Submit' | 'Close' | 'Confirmed' | 'Setup Account'
type AuthAction = 'Registration' | 'Login' | 'Automatic Login'
type AccountSetupAction = 'Setup:Step1:phoneNumber' | 'Setup:Step2:nameZipCodeBirthday' | 'Setup:Step3:interests'
type AccountSetupDecision = 'Submit' | 'Skipped' | 'Closed'
type AccountSetupConfirmationAction = 'Setup:yesPoints' | 'Setup:noPoints'

const getBaseEvent = (
  eventAction: AuthAction | AccountSetupAction | AccountSetupConfirmationAction,
  eventLabel: Interaction | AccountSetupDecision,
  nonInteractive: boolean
) => ({
  event: 'loadEventData',
  eventCategory: 'Account',
  eventAction,
  eventLabel,
  nonInteractive,
  _clear: true
})

export default {
  onImpression: (eventAction: AuthAction, formLocation: FormLocation) => ({
    ...getBaseEvent(eventAction, 'Impression', true),
    customMetrics: {
      elementView: 1
    },
    customVariables: {
      formLocation
    }
  }),

  onEmail: (eventAction: AuthAction, formLocation: FormLocation) => ({
    ...getBaseEvent(eventAction, 'Email', true),
    customMetrics: {
      elementView: 1
    },
    customVariables: {
      formLocation
    }
  }),

  onLoginSubmit: (formLocation: FormLocation, automaticLogin: boolean) => ({
    ...getBaseEvent('Login', 'Submit', false),
    eventAction: automaticLogin ? 'Automatic Login' : 'Login',
    customMetrics: {},
    customVariables: {
      formLocation
    }
  }),

  onLoginClose: (formLocation: FormLocation) => ({
    ...getBaseEvent('Login', 'Close', false),
    customMetrics: {
      elementClose: 1
    },
    customVariables: {
      formLocation
    }
  }),

  onLoginConfirmed: (formLocation: FormLocation, formMethod: FormMethods, automaticLogin: boolean) => ({
    ...getBaseEvent(automaticLogin ? 'Automatic Login' : 'Login', 'Confirmed', false),
    customMetrics: {
      elementConversion: 1
    },
    customVariables: {
      formLocation,
      formMethod
    }
  }),

  /* TNF specific: this event is not happening when a loyal registration occurs in a second step separately to the first registration */
  onRegistrationSubmit: (formLocation: FormLocation, registrationFlow: FlowTypes) => ({
    ...getBaseEvent('Registration', 'Submit', false),
    customMetrics: {},
    customVariables: {
      formLocation,
      registrationFlow
    }
  }),

  onRegistrationClose: (formLocation: FormLocation, registrationFlow: FlowTypes) => ({
    ...getBaseEvent('Registration', 'Close', false),
    customMetrics: {
      elementClose: 1
    },
    customVariables: {
      formLocation,
      registrationFlow
    }
  }),

  onRegistrationConfirmed: (
    interaction: Interaction,
    formLocation: FormLocation,
    registrationFlow: FlowTypes,
    formMethod: FormMethods,
    loyaltySignUp: boolean
  ) => ({
    ...getBaseEvent('Registration', interaction, false),
    customMetrics: {
      elementConversion: 1
    },
    customVariables: {
      formLocation,
      formMethod,
      registrationFlow,
      loyaltySignUp: loyaltySignUp ? 1 : 0
    }
  }),

  onLoyaltyStep: (
    action: AccountSetupAction,
    decision: AccountSetupDecision,
    formLocation: FormLocation
  ) => ({
    ...getBaseEvent(action, decision, false),
    customMetrics: {},
    customVariables: {
      formLocation,
      formMethod: 'Standard',
      registrationFlow: 'loyalty',
      loyaltySignUp: 1
    }
  }),

  onLoyaltySubmit: (
    action: AccountSetupConfirmationAction,
    formLocation: FormLocation
  ) => ({
    ...getBaseEvent(action, 'Confirmed', false),
    customMetrics: {},
    customVariables: {
      formLocation,
      formMethod: 'Standard',
      registrationFlow: 'loyalty',
      loyaltySignUp: 1
    }
  })
}
