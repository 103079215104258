// https://digital.vfc.com/wiki/display/ECOM15/UDO+-+User+Data+Object
import type { UserContext, UserDataObject } from '#types/gtm'

// TODO: GLOBAL15-106267
type ClvInfo = {
  clv: string
  monetary: string
  n_purch: string
  spent_past: string
}

let auth: ReturnType<typeof useAuthStore>
let user: ReturnType<typeof useUserStore>

const getClvData = (clv_info: ClvInfo) => (clv_info
  ? {
      'clvInfo-clv': clv_info.clv,
      'clvInfo-monetary': clv_info.monetary,
      'clvInfo-nPurchases': clv_info.n_purch,
      'clvInfo-spent-past': clv_info.spent_past
    }
  : {})

const mapUserDataObject = (context: UserContext) => {
  auth ??= useAuthStore()
  user ??= useUserStore()

  const baseUser = {
    'guest-id': auth.consumerId,
    'loggedIn': auth.loggedIn,
    ...context
  }

  if (auth.loggedIn) {
    return {
      ...baseUser,
      'consumer-id': auth.consumerNo,
      'loyaltyMember': user.isLoyaltyMember,
      'vfEmployee': auth.isEmployee,
      ...getClvData(user.profile?.clv_info as ClvInfo)
    } as UserDataObject
  }

  return baseUser as UserDataObject
}

export default {
  onLoadUserData: (context: UserContext) => ({
    event: 'loadUserData',
    user: mapUserDataObject(context),
    _clear: true
  })
}
