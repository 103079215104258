import { languageConfig } from '../../config/integrations/livechat'
import type { ChatPlugin } from '#types/plugin/chat'
import nuanceChat from '#core/integrations/nuance-chat'
import liveChat from '#core/integrations/live-chat'

export default defineNuxtPlugin<{ chat: ChatPlugin }>(() => {
  const { enableChat, configLivechat } = useFeatureFlags()
  const locale = useLocale()

  if (!enableChat) {
    const open = () => console.warn('Chat is disabled by feature flag')
    open()

    return {
      provide: {
        chat: {
          open,
          available: ref(false)
        }
      }
    }
  }

  // TBL CA uses GTM to inject live chat
  if (['fr-ca', 'en-ca'].includes(locale))
    return

  if (configLivechat.enabled) {
    return liveChat({
      brand: 'TBL',
      languageConfig,
      customStyleLocales: ['uk-en', 'de-de', 'it-it', 'fr-fr', 'es-es', 'nl-nl', 'us-es'],
      cssPath: '/livechat/css/LiveChatCodeCanvasTBL.css',
      logoUrl: 'https://vfeurope.lightning.force.com/servlet/servlet.ImageServer?id=0153X0000076sFv&oid=00Db0000000dbnM',
      avatarUrl: 'https://vfeurope.lightning.force.com/servlet/servlet.ImageServer?id=0153X000006McR0&oid=00Db0000000dbnM'
    })
  }
  else {
    return nuanceChat({ eventName: 'launchTBLDesktop' })
  }
})
