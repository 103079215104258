import type { LDContext, LDClient as LDNodeClient } from 'launchdarkly-node-server-sdk'
import type { LDClient } from 'launchdarkly-js-client-sdk'
import { basicLogger, init } from 'launchdarkly-node-server-sdk'
import type { H3Event } from 'h3'
import { api } from '#root/env/private'
import { ldSource } from '#root/env/common'
import type { FeatureFlags } from '#core/server/types/featureFlags'
import { log } from '#core/utils/logger'
import { getLDContext } from '#core/utils/launchDarklyContext'

let launchDarklyClient: LDNodeClient

/**
 * Initializes the LaunchDarkly client. Passes an API key to the SDK and then waits for the initialization process to be completed.
 * @returns A Promise resolving to the initialized LaunchDarkly client.
 * @category Utils
 */
const initialize = async () => {
  const client = init(api.launchDarklyServerSdkKey, {
    logger: basicLogger({
      level: 'info',
      destination: log.info
    })
  })
  await client.waitForInitialization()
  return client
}

/**
 * Retrieves the LaunchDarkly client instance, initializing it if necessary in order to always return an initialized client
 * @returns A Promise resolving to the LaunchDarkly client instance.
 * @category Utils
 */
export const getLaunchDarklyClient = async () => {
  if (launchDarklyClient) return launchDarklyClient
  launchDarklyClient = await initialize()
  return launchDarklyClient
}

/**
 * Retrieves feature flags from LaunchDarkly and local storage, combining them into a single object.
 * @param event The event triggering the retrieval of feature flags.
 * @returns A Promise resolving to the combined feature flags object.
 * @category Utils
 */
export const getFeatureFlags = async (event: H3Event): Promise<FeatureFlags> => {
  const localFlags = (await useStorage().getItem(`root/${ldSource}`)) as FeatureFlags
  try {
    const client = await getLaunchDarklyClient()
    const flags = (await client.allFlagsState(getLDContext(getHeaders(event)))).toJSON()
    return { ...flags, ...localFlags, localFlags } as FeatureFlags
  }
  catch (err: any) {
    log.error('[Canvas:LD:Server] Failed to retrieve feature flags, using fallback data', err)
  }

  return localFlags
}

/**
 * Retrieves the details of feature flags that are currently in experiment.
 * @param featureFlags fetched feature flags.
 * @param context Context for fetching details.
 * @param client LDClient instance.
 * @returns Feature flags that are in experiment and information on active variant.
 * @category Utils
 */
export const getExperiments = (featureFlags: FeatureFlags, context: LDContext, client: LDClient,) => {
  const experiments: Partial<{ [key in keyof FeatureFlags]: any }> = {}

  try {
    Object.entries(featureFlags)
      .forEach(([key]) => {
        // exclude entries that can't be an experiment
        if (key.startsWith('$') || key === 'localFlags') return
        const variationDetail = client.variationDetail(key, context)
        if (variationDetail.reason?.inExperiment) experiments[key] = variationDetail.value
      })
  }
  catch (err: any) {
    log.error('[Canvas:LD] Experiments could not be fetched', err)
  }

  return experiments
}
