<template>
  <dialog-employee-terms lazy />
  <dialog-forgot-password />
  <dialog-interests lazy />
  <dialog-klarna lazy />
  <!-- This z-index is +1 higher than the cookie popup. We want this popup to show on top of the cookie popup -->
  <dialog-location-confirmation hide-close-button style="z-index: 2147483646 !important" />
  <dialog-loyalty-sign-up />
  <dialog-mini-cart size="lg" />
  <dialog-sign-in />
  <dialog-sign-in-confirmation lazy :size="{ sm: 'full', md: 'md' }" />
  <dialog-sign-up />
  <dialog-three-ds-challenge disable-outside-interaction hide-close-button />
  <dialog-welcome />
</template>

<script lang="ts" setup>
const {
  DialogEmployeeTerms,
  DialogForgotPassword,
  DialogInterests,
  DialogKlarna,
  DialogLocationConfirmation,
  DialogLoyaltySignUp,
  DialogMiniCart,
  DialogSignIn,
  DialogSignInConfirmation,
  DialogSignUp,
  DialogThreeDsChallenge,
  DialogWelcome
} = useDialogsStore()
</script>
