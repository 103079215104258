import routerOptions0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@netlify+blobs@7.3.0_@parcel+watcher@2.4.1_@types+node@22.10.1_@unocss+rese_324186aeaf9aee3ac690174658131eca/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/build/repo/app/router.options.ts";
const configRouterOptions = {
  scrollBehaviorType: "smooth",
  hashMode: false
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}