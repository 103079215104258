/**
 * 1.5 to Canvas PDP & PLP Middleware
 * The goal is to temporarily restructure requested 1.5 URLS to canvas URLs
 * while development is still underway
 *
 * The issue: The backend can not switch the TNF catalog over to using Canvas style URLs until cutover
 * yet for development to proceed we need a valid data set.
 *
 * The solution: We have all the URL elements to make a canvas URL but they are out of order.
 * This middleware reorders the parts of the URL to create a canvas URL from the provided 1.5 URL
 *
 * Examples:
 *  PLP:
 *    1.5: /en-us/mens-c211701
 *    Canvas: /en-us/c/mens-211701
 *  PDP:
 *    1.5: /en-us/mens/mens-jackets-c211702/mens-tnf-packable-jacket-pNF0A7ZZ4?color=JK3
 *    Canvas: /en-us/p/mens/mens-jackets-c211702/mens-tnf-packable-jacket-NF0A7ZZ4?color=JK3
 */

export default defineNuxtRouteMiddleware(({ path, query, hash }) => {
  const { enable15ToCanvasUrlProxy } = useFeatureFlags()
  if (!enable15ToCanvasUrlProxy) return

  const categoryMatch = path.match(CATEGORY_15_URL_REGEX)
  const productMatch = path.match(PRODUCT_15_URL_REGEX)
  const match = categoryMatch || productMatch

  if (!match) return

  const [_, locale, category, prefix, id] = match
  const redirectPath = `/${locale}/${prefix}/${category}-${id.toLocaleUpperCase()}`

  return navigateTo({
    path: redirectPath,
    query,
    hash
  })
})
