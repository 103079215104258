<template>
  <div class="fixed inset-x-0 z-toast container" :style="{ top: pxToRem(header.height.full) }">
    <transition-group
      appear
      aria-live="polite"
      class="pointer-within absolute inset-y-0 right-0 pt-4 <md:w-full space-y-4 md:pr-4 "
      data-test-id="screen-toast"
      enter-active-class="transform ease-out duration"
      enter-from-class="op-0 -translate-y-40 will-change-transform"
      leave-active-class="transform ease-in duration"
      leave-to-class="op-0 -translate-y-40"
      role="log"
      tag="div"
      @mouseout="resume"
      @mouseover="pause"
    >
      <vf-toast v-for="{ id, props } in toasts" :key="id" v-bind="props" @close="remove(id)" />
    </transition-group>
  </div>
</template>

<script lang="ts" setup>
const header = useHeaderStore()
const { pause, remove, resume, toasts } = useToaster()
</script>
