import type { MonetateProductObject, P13nImpressionContext, ProductContext, ShopTheLookContext } from '#types/gtm'
import type { CartItem } from '#types/cart'
import type { DetailsData, Product, ProductCardExtended, ProductSetItem } from '#root/api/clients/product/data-contracts'
import type { ProductDetails } from '#types/product'
import type { MonetateMappedExperience, MonetateRecommendedProduct } from '#types/p13n'

const mapFilters = (filters) => {
  if (filters.length)
    return Object.entries(JSON.parse(filters)).map(([category, values]) => ({ category, values }))
  return []
}

const getBaseEvent = (eventLabel?: string | undefined, nonInteractive = true) => ({
  eventCategory: 'Enhanced Ecommerce',
  eventLabel,
  customMetrics: {},
  customVariables: {},
  nonInteractive,
  _clear: true
})

export default {
  // https://digital.vfc.com/wiki/display/ECOM15/a.+productImpressions
  onProductImpression: (
    products: CartItem[] | ProductCardExtended[],
    filters: string,
    context: ProductContext
  ) => ({
    ...getBaseEvent(),
    event: 'productImpressions',
    eventAction: 'Product Impressions',
    ecommerce: {
      currencyCode: products[0]?.currency,
      impressions: getProductObjectList(products, context)
    },
    customVariables: {
      list_type: 'grid',
      productLayout: `${context.layout} column`,
      sortOption: context.sortOption,
      filters: mapFilters(filters)
    }
  }),
  //
  onProductSetImpression: (products: ProductSetItem[], context: ShopTheLookContext) => ({
    ...getBaseEvent(),
    event: 'productImpressions',
    eventAction: 'Product Impressions',
    ecommerce: {
      currencyCode: context.currency,
      impressions: getProductSetItemObjectList(products, context)
    },
    customVariables: {
      list_type: 'Shop the Look'
    }
  }),
  // https://digital.vfc.com/wiki/display/ECOM15/a.+productImpressions#:~:text=dataLayer%20event%3A%20productRecImpressions
  onProductRecImpressions: (
    products: MonetateRecommendedProduct[],
    { currency, experience, list, title }: P13nImpressionContext
  ) => ({
    event: 'productImpressions',
    eventCategory: 'Enhanced Ecommerce',
    eventAction: 'Product Impressions',
    nonInteractive: true,
    ecommerce: {
      currencyCode: currency,
      impressions: getMonetateProductObjectList(products, { list, title })
    },
    customMetrics: {},
    customVariables: {
      list_type: list.toLowerCase(),
      recs_set: experience?.component,
      experience_id: experience?.id,
      experience_label: experience?.label,
      experience_name: experience?.name,
      recs_set_id: experience?.actionId
    },
    _clear: true
  }),
  // https://digital.vfc.com/wiki/display/ECOM15/b.+productClick
  onProductClick: (product: CartItem | Product | ProductDetails, context: ProductContext) => ({
    ...getBaseEvent(product.id, false),
    event: 'productClick',
    eventAction: 'Product Clicks',
    ecommerce: {
      currencyCode: product.currency,
      click: {
        actionField: {
          action: context.action,
          affiliation: `${useAppConfig().api.brandPrefix}-${useCountryCode()}`,
          list: context.list || getListDescription({
            breadcrumbs: getPageBreadcrumb(context.breadcrumbs)?.join(' - '),
            searchTerm: context.searchTerm
          }),
          merchProductId: product.id
        },
        products: getProductObjectList([product] as CartItem[] | DetailsData[], context)
      }
    }
  }),
  //
  onProductSetClick: (product: ProductSetItem, context: ShopTheLookContext) => ({
    ...getBaseEvent(product.id, false),
    event: 'productClick',
    eventAction: 'Product Clicks',
    ecommerce: {
      currencyCode: context.currency,
      click: {
        actionField: {
          action: 'Click Shop the Look Item',
          affiliation: `${useAppConfig().api.brandPrefix}-${useCountryCode()}`,
          list: 'Shop the Look',
          merchProductId: product.id
        },
        products: getProductSetItemObjectList([product], context)
      }
    }
  }),
  // https://digital.vfc.com/wiki/display/ECOM15/b.+productClick#:~:text=dataLayer%20event%3A%20productRecClick
  onProductRecClick: (
    product: MonetateRecommendedProduct,
    { action, currency, experience, list, title, placememt, recommendationId, vendor }: P13nImpressionContext
  ) => {
    const products = getMonetateProductObjectList([product], { list, title })
    const _product = products[0]

    setInteractionOrigin('recommendation-carousel')
    setGtmCartProductsMap(_product.id!, _product)

    return {
      ...getBaseEvent(product.id, false),
      event: 'productClick',
      eventAction: 'Product Clicks',
      ecommerce: {
        currencyCode: currency,
        click: {
          actionField: {
            action,
            list: _product.list,
            category: _product.list
          },
          products
        }
      },
      customVariables: {
        list_type: list.toLowerCase(),
        recs_set: experience?.component,
        experience_id: experience?.id,
        experience_label: experience?.label,
        experience_name: experience?.name,
        recs_set_id: experience?.actionId,
        placememt,
        recommendationId,
        vendor
      },
    }
  },
  // https://digital.vfc.com/wiki/display/ECOM15/c.+productDetailView
  onProductDetailView: (product: any, context: ProductContext) => ({
    ...getBaseEvent(product.id),
    event: 'productDetailView',
    eventAction: 'Product Detail View',
    ecommerce: {
      currencyCode: product.currency,
      detail: {
        actionField: {
          viewType: context.viewType,
          list: getListDescription({
            breadcrumbs: getPageBreadcrumb(context.breadcrumbs)?.join(' - '),
            searchTerm: context.searchTerm,
            productId: product.id
          })
        },
        products: getProductObjectList([product] as CartItem[] | DetailsData[], {
          omitPosition: true,
          ...context
        })
      }
    }
  }),
  // https://digital.vfc.com/wiki/display/ECOM15/d.+addToCart+and+removeFromCart
  onRemoveFromCart: () => {
    // To be implemented
  },
  // https://digital.vfc.com/wiki/display/ECOM15/e.+checkout
  onCheckout: () => {
    // To be implemented
  },
  // https://digital.vfc.com/wiki/display/ECOM15/f.+checkoutOption
  onCheckoutOption: () => {
    // To be implemented
  },
  // https://digital.vfc.com/wiki/display/ECOM15/g.+checkoutCompletion
  onCheckouCompletion: () => {
    // To be implemented
  },
  // https://digital.vfc.com/wiki/display/ECOM15/h.+cartUpdate
  onCartUpdate: () => {
    // To be implemented
  },
  // https://digital.vfc.com/wiki/display/ECOM15/i.+monetateActiveExperiences
  onMonetateActiveExperiences: (experiences: MonetateMappedExperience[]) => {
    const event = 'monetateActiveExperiences'

    const monetateExperiences = experiences.map(({ id, label, variant }) => ({
      id,
      key: label,
      split: variant,
    }))

    const recommendations = {
      products: experiences.reduce(
        (acc: Partial<MonetateProductObject>[], experience) => [
          ...acc,
          ...getMonetateProductObjectList(experience.items || [], { experience }),
        ],
        []
      ),
    }

    const duplicateEvent = window.dataLayer?.find(
      (entry) =>
        entry.event === event
        && entry.recommendations
        && entry.monetateExperiences
        && JSON.stringify(entry.recommendations) === JSON.stringify(recommendations)
        && JSON.stringify(entry.monetateExperiences) === JSON.stringify(monetateExperiences)
    )

    if (duplicateEvent)
      return null

    return {
      event,
      eventCategory: 'Monetate',
      eventAction: 'Active Experiences',
      nonInteractive: true,
      monetateExperiences,
      recommendations,
      customMetrics: {},
      customVariables: {},
      _clear: true,
    }
  },
}
