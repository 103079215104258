/**
 * clearProductionCookies
 *
 * When using shared cookies, we are setting up cookies as the TLD closest to the environment we are working on
 * otw.dev2.vans.com and dev2.vans.com share the ".dev2.vans.com" cookies
 * otw.vans.com and vans.com share ".vans.com" cookies.
 *
 * If a user is accessing vans.com and then goes to dev2.vans.com, it will be having two cookies with the same value because both vans.com and dev2.vans.com will share the .vans.com cookies.
 * So we need to clear all the production cookies on non-prod environment
 * Explanation: https://stackoverflow.com/questions/1062963/how-do-browser-cookie-domains-work
 */
export const clearProductionCookies = () => {
  const host = useHost()
  const siteId = useSiteId()
  const { targetEnv } = useRuntimeConfig().public

  const currentEnv = targetEnv.toLowerCase()

  if (host.includes(currentEnv)) {
    const tld = host.substring(host.indexOf(currentEnv) + currentEnv.length + 1)

    Object.values(cookiesPostfix).forEach((value) => {
      // We require the cookies to be written like this. The ; represents another property on the same cookie. Don't try to "break it" into multiple lines
      document.cookie = `vfa_${siteId}_${value}=;domain=${tld};Max-Age=0`
    })
  }
}
