const baseEvent = {
  event: 'loadEventData',
  eventCategory: 'Social Native',
  nonInteractive: false,
  customMetrics: {},
  _clear: true
}

export default {
  onClickOpenModal: (eventAction: string, customVariables: Record<string, string> = {}) => ({
    ...baseEvent,
    eventAction,
    eventLabel: 'Open Modal',
    customVariables
  }),
  onClickCloseModal: (eventAction: string, customVariables: Record<string, string> = {}) => ({
    ...baseEvent,
    eventAction,
    eventLabel: 'Close Modal',
    customVariables
  }),
  onClickPrev: (customVariables: Record<string, string> = {}) => ({
    ...baseEvent,
    eventAction: 'Shop the Look',
    eventLabel: 'Move to Previous Post',
    customVariables
  }),
  onClickNext: (customVariables: Record<string, string> = {}) => ({
    ...baseEvent,
    eventAction: 'Shop the Look',
    eventLabel: 'Move to Next Post',
    customVariables
  }),
  onEmailSubmit: (customVariables: Record<string, string> = {}) => ({
    ...baseEvent,
    eventAction: 'Add Your Photo',
    eventLabel: 'Submit Email',
    customVariables
  }),
  onUploadPhoto: (customVariables: Record<string, string> = {}) => ({
    ...baseEvent,
    eventAction: 'Add Your Photo',
    eventLabel: 'Upload Photo',
    customVariables
  }),
  onSubmitPhoto: (customVariables: Record<string, string> = {}) => ({
    ...baseEvent,
    eventAction: 'Add Your Photo',
    eventLabel: 'Submit Photo',
    customVariables
  }),
  onSubmitCaption: (customVariables: Record<string, string> = {}) => ({
    ...baseEvent,
    eventAction: 'Add Your Photo',
    eventLabel: 'Submit for Approval',
    customVariables
  }),
  onClickExploreGallery: (customVariables: Record<string, string> = {}) => ({
    ...baseEvent,
    eventAction: 'Explore the Gallery',
    customVariables
  })
}
