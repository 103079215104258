import type { SupportedBrand, TargetEnv } from '#types/env'

const brandLocations = {
  canvas: {},
  otw: {},
  thenorthface: {
    emea: [],
    nora: []
  },
  timberland: {
    emea: [],
    nora: []
  },
  vans: {
    emea: [],
    nora: []
  }
}

export const api = {
  mockConfig: process.env.API_MOCK_CONFIG || 'false'
}
export const apiRoutePrefix = process.env.API_ROUTE_PREFIX || '/fapi'
export const brand: SupportedBrand = process.env.BRAND?.toLowerCase() as SupportedBrand || 'canvas'
export const buildDir = process.env.BUILD_NUXT_FOLDER || '.nuxt'
export const outputDir = process.env.BUILD_OUTPUT_FOLDER || '.output'
export const cmsApiRoutePrefix = process.env.CMS_API_ROUTE_PREFIX || '/fcontent'
export const currency = {
  'cs-cz': 'CZK',
  'da-dk': 'DKK',
  'de-at': 'EUR',
  'de-be': 'EUR',
  'de-ch': 'CHF',
  'de-de': 'EUR',
  'de-lu': 'EUR',
  'en-ca': 'CAD',
  'en-ch': 'CHF',
  'en-cz': 'CZK',
  'en-dk': 'DKK',
  'en-fi': 'EUR',
  'en-gb': 'GBP',
  'en-ie': 'EUR',
  'en-lu': 'EUR',
  'en-pt': 'EUR',
  'en-se': 'SEK',
  'en-us': 'USD',
  'es-us': 'USD',
  'es-es': 'EUR',
  'fr-be': 'EUR',
  'fr-ca': 'CAD',
  'fr-ch': 'CHF',
  'fr-fr': 'EUR',
  'fr-lu': 'EUR',
  'it-ch': 'CHF',
  'it-it': 'EUR',
  'nl-be': 'EUR',
  'nl-nl': 'EUR',
  'pl-pl': 'PLN',
  'pt-pt': 'EUR',
  'sv-fi': 'EUR',
  'sv-se': 'SEK'
}
export const region = process.env.REGION?.toLowerCase()
export const country = process.env.COUNTRY?.toLowerCase()
export const gitHash = process.env.NUXT_PUBLIC_GIT_HASH || 'none'
export const isDev = process.env.NODE_ENV === 'development'
export const isNetlify = process.env.NETLIFY === 'true'
export const monetateBaseUrl = isDev ? 'https://engine.monetate.net/api/engine/v1' : '/decision/api/engine/v1'
export const monetateHost = process.env.MONETATE_HOST
export const serverRequestTimeout = process.env.NUXT_PUBLIC_SERVER_REQUEST_TIMEOUT || 20000
export const supportedBrands = Object.keys(brandLocations) as SupportedBrand[]
export const supportedRegions = brandLocations[brand] ? Object.keys(brandLocations[brand]) : []
export const supportedCountries = region && supportedRegions.length ? brandLocations[brand][region] : []
export const isRegionLayerEnabled = region && supportedRegions.includes(region)
export const isCountryLayerEnabled = country && supportedCountries?.includes(country)
export const targetEnv: TargetEnv = process.env.NUXT_PUBLIC_TARGET_ENV as TargetEnv || 'DEV'
export const disableDomainRestriction = process.env.DISABLE_DOMAIN_RESTRICTION === 'true'
export const disableProxyForNetlify = process.env.DISABLE_PROXY_FOR_NETLIFY === 'true'
export const staticAssetVersion = 2
export const launchDarklyClientKey: string = process.env.LAUNCHDARKLY_CLIENT_KEY || '618d2e61db10c1162486883b'
export const ldSource = process.env.LD_SOURCE || 'feature-flags.local.json'
