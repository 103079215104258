import { useScript } from '@unhead/vue'
import { hasCookieConsentInteraction } from '#core/utils/cookieConsent'

declare global {
  interface Window {
    OptanonWrapper: () => void
    OneTrust: {
      ToggleInfoDisplay: () => void
    }
  }
}

declare module '#app' {
  interface NuxtApp {
    $cookieConsent: Ref<ConsentStatus>
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $cookieConsent: Ref<ConsentStatus>
  }
}

function waitForProperty(property: string, interval = 500, attempts = 3): Promise<void> {
  return new Promise((resolve, reject) => {
    let retryCount = 0

    const checkProperty = setInterval(() => {
      retryCount++
      if (window[property]) {
        clearInterval(checkProperty)
        resolve()
      }
      else if (retryCount >= attempts) {
        clearInterval(checkProperty)
        reject(new Error(`Property ${property} not found after ${attempts} attempts`))
      }
    }, interval)
  })
}

export default defineNuxtPlugin({
  name: 'oneTrust',
  parallel: true,
  async setup({ provide }) {
    const route = useRoute()
    const router = useRouter()
    const { isPreview } = useCms()
    const { configGtm: { onetrust_id } } = useFeatureFlags()

    // OneTrust script should not be loaded in preview mode
    // Content preview is shown in iframe and this script could not set 3rd party cookies
    // Without this restriction user have to accept cookies infinitely

    if (!onetrust_id || isPreview) return

    const cookieConsent = ref(getCookieConsent())

    const { OptanonWrapper } = window

    window.OptanonWrapper = function () {
      OptanonWrapper?.()

      cookieConsent.value = getCookieConsent()
    }

    const { $script } = useScript({
      src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      ['data-domain-script' as any]: isObject(onetrust_id) ? onetrust_id[useLocale()] : onetrust_id,
      ['data-language' as any]: useLocale()
    }, { trigger: 'manual' })

    useStyleTag('#onetrust-banner-sdk { pointer-events: auto; }')

    if (!hasCookieConsentInteraction())
      $script.load()

    /**
     * Cookie settings hash is used to open cookie settings modal
     * This approach is used because it's not possible to specify link ID in the RichText Editor and use script binding by ID
     *
     * Recommended usage per OneTrust documentation:
     * <a id="ot-sdk-btn">Cookie Settings</a>
     * Our implementation:
     * <a href="#TrustTool">Cookie Settings</a>
     */
    const cookieSettingsHash = '#TrustTool'

    watch(() => route.hash, async () => {
      if (route.hash === cookieSettingsHash) {
        router.replace({ hash: undefined })

        if ($script.status.value !== 'loaded') {
          await $script.load()
          await waitForProperty('OneTrust')
        }

        window.OneTrust.ToggleInfoDisplay()
      }
    })

    provide('cookieConsent', cookieConsent)
  }
})
